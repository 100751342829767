/* linha 1-69 - estilos Draft, não utilizados mais?
70 em diante estilos ckedtior */

.wrapper-class {
  padding: 0.25rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  min-height: 300px;
  max-height: 900px;
}
.wrapper-class-small {
  padding: 0.25rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  min-height: 100px;
  max-height: fit-content;
}
.editor-class-small {
  background-color: #f8f8f8;
  padding: 0.2rem;
  border: 1px solid #ccc;
  margin: 0;
  width: 100%;
  min-height: 100px;
  height: calc(100% - 100px);
  max-height: fit-content;
  overflow: auto;
}
.editor-class {
  background-color: #f8f8f8;
  padding: 0.2rem;
  border: 1px solid #ccc;
  margin: 0;
  width: 90%;
  min-height: 300px;
  max-height: fit-content;
  height: calc(100% - 50px);
  max-height: calc(100vh - 200px);
  overflow: auto;
}
.toolbar-class-focus {
  border: 1px solid #ccc;
}
.rdw-suggestion-dropdown {
  min-width: 300px;
}

/*   CKEDITOR    */
:root {
  --ck-z-default: 1400 !important;
  --ck-z-modal: calc(var(--ck-z-default) + 999);
}

.document-editor {
  width: 100%;
  height: 100%;
  border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);

  /* This element is a flex container for easier rendering. */
  display: flex;
  flex-flow: column nowrap;
}

.document-editor-small {
  width: 100%;
  height: 100%;
  border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);

  /* This element is a flex container for easier rendering. */
  display: flex;
  flex-flow: column nowrap;
}

.document-editor__toolbar {
  /* Make sure the toolbar container is always above the editable. */
  z-index: 1;

  /* Create the illusion of the toolbar floating over the editable. */
  box-shadow: 0 0 5px hsla(0, 0%, 29%, 0.2);

  /* Use the CKEditor CSS variables to keep the UI consistent. */
  border-bottom: 1px solid var(--ck-color-toolbar-border);
}

/* Adjust the look of the toolbar inside the container. */
.document-editor__toolbar .ck-toolbar {
  border: 0;
  border-radius: 0;
}

/* Make the editable container look like the inside of a native word processor application. */
.document-editor__editable-container {
  padding: calc(1.8 * var(--ck-spacing-large));
  background: var(--ck-color-base-foreground);

  /* Make it possible to scroll the "page" of the edited content. */
  overflow-y: scroll;
}

.document-editor__editable-container .ck-editor__editable {
  /* Set the dimensions of the "page". */
  width: 23cm;
  min-height: 26cm;
  font-size: large;
  /* Keep the "page" off the boundaries of the container. */
  padding: 1cm 2cm 2cm;

  border: 1px hsl(0, 0%, 82.7%) solid;
  border-radius: var(--ck-border-radius);
  background: white;

  /* The "page" should cast a slight shadow (3D illusion). */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1);

  /* Center the "page". */
  margin: 0 auto;
}

.document-editor__editable-container-small .ck-editor__editable {
  /* Set the dimensions of the "page". */
  width: 100%;
  min-height: 100px;
  /* Keep the "page" off the boundaries of the container. */
  padding: 10px;

  /* border: 1px hsl(0, 0%, 82.7%) solid; */
  border-radius: var(--ck-border-radius);
  background: white;

  /* The "page" should cast a slight shadow (3D illusion). */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1);

  border-radius: 10px;
  /* Center the "page". */
  margin: 0 auto;
}

.ck.editor[role='textbox'] {
  background: #fff;
  font-size: 2em;
  line-height: 1.6em;
  min-height: var(--ck-sample-editor-min-height);
  padding: 1.5em 2em;
}

.ck.ck-editor__editable {
  background: #fff;
  border: 1px solid hsl(0, 0%, 70%);
}
